import { LANGUAGE_KEY_MAP } from 'wlp-client-common/consts/languageConsts';
import LocalStorageUtils from './LocalStorageUtils';
import i18n from 'i18nxt';
import wlpMessages_ja_JPProperties from 'wlp-client-common/locales/wlpMessages_ja_JP.properties';
import wlpMessages_en_USProperties from 'wlp-client-common/locales/wlpMessages_en_US.properties';
import LocaleConst from 'wlp-client-common/consts/LocaleConst';

const localStorageUtil = new LocalStorageUtils();

class WlpLanguageUtil {

    constructor(){
        this.keyMap = Object.keys(LANGUAGE_KEY_MAP);
    }

    getDefaultLanguage(){
        for(const langueKey of this.keyMap){
            const langueValue = LANGUAGE_KEY_MAP[langueKey];
            //find browser language in list support language
            if(langueValue.indexOf(navigator.language) > -1){
                return langueKey;
            }
        }

        //if browser language is not valid => return default language;
        return this.keyMap[0];
    }

    getLanguaeByLocaleCode(code){
        for(const langueKey of this.keyMap){
            const langueValue = LANGUAGE_KEY_MAP[code];
            //find provided language code in list support language
            if(langueValue.indexOf(navigator.language) > -1){
                return langueKey;
            }
        }

        //if provided language code is not valid => return default language;
        return this.keyMap[0];
    }

    getCurrentLanguage(){
        return localStorageUtil.getLocalStrage(localStorageUtil.STRAGE_LOCALE_CODE);
    }

    getLookupKey(){
        return localStorageUtil.STRAGE_LOCALE_CODE;
    }

    setLocaleLanguage(lang){
        i18n.changeLanguage(lang);
        localStorageUtil.setLocalStrage(localStorageUtil.STRAGE_LOCALE_CODE, lang);
    }

    getMessageByKey = (messageKey) => {
        let localeCode = localStorageUtil.getLocaleCode();
        const errorMessage =
            localeCode === LocaleConst.ja_JP
                ? wlpMessages_ja_JPProperties[messageKey]
                : wlpMessages_en_USProperties[messageKey];
        return errorMessage;
    };
}

export default WlpLanguageUtil;