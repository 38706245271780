import i18nxt from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from './wlp-client-resource/locales/en_US';
import translationJA from './wlp-client-resource/locales/ja_JP';
import WlpLanguageUtil from "wlp-client-common/utils/WlpLanguageUtil";

const languageUtils = new WlpLanguageUtil();


// the translations
const langEn = Object.assign({}, translationEN);
const langJa = Object.assign({}, translationJA);

const resources = {
    'en_US': {
        translation: langEn
    },
    'ja_JP': {
        translation: langJa
    },
};

const lookup = 'localeCode';

i18nxt
    .use(initReactI18next) // passes i18nxt down to react-i18next
    .init({
        resources: resources,
        lng: languageUtils.getCurrentLanguage() || languageUtils.getDefaultLanguage(),
        fallbackLng: languageUtils.getDefaultLanguage(),

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        },
        detection: {
            lookupLocalStorage: lookup,
            lookupCookie: lookup
        }
});

export default i18nxt;