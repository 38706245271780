import React from 'react';
import './PreloadDisplay.css';

class PrealoadDisplay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            progressValue: 1
        }
        this.loadingProgress = React.createRef();
    }

    updateProgress = () => {
        const { delayLoad } = this.props;
        const { progressValue } = this.state;
        if (progressValue >= 100) {
            clearInterval(this.updateProgress);
        } else {
            const frame = 1000 / delayLoad;
            this.setState({ progressValue: progressValue + frame });
        }
    }

    componentDidMount() {
        const { delayLoad } = this.props;
        if (typeof delayLoad === 'number') {
            this.loadingProgress.current = setInterval(this.updateProgress, 10);
        }
    }

    componentWillUnmount() {
        if (this.loadingProgress.current) {
            clearInterval(this.loadingProgress.current);
        }
    }
    
    render() {
        const { progressValue } = this.state;
        return (
            <div className="loader">
                <div className="loader-progress-bg">
                    <div className="loader-progress" style={{ width: `${progressValue}%` }} />
                </div>
            </div>
        )
    }

}

export default PrealoadDisplay;