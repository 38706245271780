export default ({
    'global.ok': 'OK',
    'global.cancel': 'Cancel',
    'manager.pages.btn.search': 'Search',
    'manager.pages.btn.clear': 'Clear',
    'manager.pages.btn.save': 'Save',
    'manager.pages.btn.notsave': 'Not save',
    'manager.pages.btn.back': 'Back',
    'manager.pages.btn.approve': 'Approve',
    'manager.pages.btn.unlock': 'Unlock',
    'manager.pages.btn.awake': 'Awake',
    'manager.pages.btn.suspend': 'Suspend',
    'manager.pages.btn.lock': 'Lock',
    'manager.pages.btn.delete': 'Delete',
    'manager.pages.btn.submit': 'Submit',
    'manager.pages.btn.cancel': 'Cancel',
    'manager.pages.alert.al001': 'Property has changed. Save this changes ?',
    'manager.pages.alert.al002': 'Enter your password...',
    'manager.pages.label.password': 'Password',
    'manager.login.id': 'ID',
    'manager.login.pass': 'PASS',
    'manager.header.logout': 'Logout',
    'manager.login.login': 'Login',
    'manager.pages.stats': 'Stats',
    'manager.pages.contract': 'Contract',
    'manager.pages.user': 'User',
    'manager.pages.admin': 'Admin',
    'manager.login.message.error': 'Login error',
    'manager.pages.stats.plan.all': 'All plan',
    'manager.pages.stats.plan.all.plans': 'Plans',
    'manager.pages.stats.plan.all.teams': 'Teams',
    'manager.pages.stats.plan.all.users': 'Users',
    'manager.pages.stats.plan.small': 'Small plan',
    'manager.pages.stats.plan.small.plans': 'Plans',
    'manager.pages.stats.plan.small.teams': 'Teams',
    'manager.pages.stats.plan.small.users': 'Users',
    'manager.pages.stats.plan.medium': 'Medium plan',
    'manager.pages.stats.plan.medium.plans': 'Plans',
    'manager.pages.stats.plan.medium.teams': 'Teams',
    'manager.pages.stats.plan.medium.users': 'Users',
    'manager.pages.stats.plan.large': 'Large plan',
    'manager.pages.stats.plan.large.plans': 'Plans',
    'manager.pages.stats.plan.large.teams': 'Teams',
    'manager.pages.stats.plan.large.users': 'Users',
    'manager.pages.stats.contents': 'Contents',
    'manager.pages.stats.contents.users': 'Users',
    'manager.pages.stats.contents.maps': 'Maps',
    'manager.pages.stats.contents.sheets': 'Sheets',
    'manager.pages.stats.contents.items': 'Items',
    'manager.pages.stats.contents.base.items': 'Base Items',
    'manager.pages.stats.contents.attachments': 'Attachments',
    'manager.pages.stats.contents.size.of.attachments': 'Size of Attachments(MB)',
    'manager.pages.stats.download.tsv': 'Download TSV',
    'manager.pages.stats.other.stats': 'Other Stats',
    'manager.pages.label.password.confirm': 'Password confirm',

    'manager.pages.user.btn.options': 'Options',
    'manager.pages.user.personalid': 'Personal ID',
    'manager.pages.user.email': 'Email',
    'manager.pages.user.name': 'Name',
    'manager.pages.user.joining': 'Joining',
    'manager.pages.user.registered': 'Registered',
    'manager.pages.user.lastlogin': 'Last logged in',
    'manager.pages.user.licence': 'Licence',
    'manager.pages.user.license': 'License',
    'manager.pages.user.status': 'Status',
    'manager.pages.user.teams': 'teams',
    'manager.pages.user.firstname': 'First name',
    'manager.pages.user.lastname': 'Last name',
    'manager.pages.user.timezone': 'Timezone',
    'manager.pages.user.language': 'Language',
    'manager.pages.user.joinedcompanies': 'Joined companies',
    'manager.pages.user.joinedteams': 'Joined Teams',
    'manager.pages.user.company': 'Company',
    'manager.pages.user.note': 'Note',
    'manager.pages.user.savenote': 'Save note',
    'manager.pages.user.suspendaccount': 'Suspend account',
    'manager.pages.user.message.suspendaccount': 'Suspend this account ?',
    'manager.pages.user.awakeaccount': 'Awake account',
    'manager.pages.user.message.awakeaccount': 'Awake this account ?',
    'manager.pages.user.deleteaccount': 'Delete account',
    'manager.pages.user.message.deleteaccount': 'Delete this account ?',
    'manager.pages.user.message.deleteaccount.leader': 'Delete this account ?\nIf the user is a team leader, the team will also be deleted.',
    'manager.pages.user.userdetail.none': 'None',

    'manager.pages.contract.id': 'ID',
    'manager.pages.contract.plan': 'Plan',
    'manager.pages.contract.status': 'Status',
    'manager.pages.contract.button.search': 'Search',
    'manager.pages.contract.body.grade': 'Grade',
    'manager.pages.contract.body.company': 'Company',
    'manager.pages.contract.body.lockout': 'Lockout',
    'manager.pages.contract.body.admin1': 'Admin1',
    'manager.pages.contract.body.admin2': 'Admin2',
    'manager.pages.contract.body.request': 'Request',
    'manager.pages.contract.body.approve': 'Approve',
    'manager.pages.contract.body.start': 'Start',
    'manager.pages.contract.body.image.base': 'Poster Feature',
    'manager.pages.contract.body.image.item': 'Image Item Feature ＆ Preset Feature',

    'manager.pages.contract.contractdetail.button.back': 'Back',
    'manager.pages.contract.contractdetail.button.download.tsv': 'Download TSV',
    'manager.pages.contract.contractdetail.custome.code': 'Customer code',
    'manager.pages.contract.contractdetail.issue.code': 'Issue code',
    'manager.pages.contract.contractdetail.status': 'Status',
    'manager.pages.contract.contractdetail.mamager.url': 'Manager URL',
    'manager.pages.contract.contractdetail.company.login.id': 'Company Login ID',
    'manager.pages.contract.contractdetail.password': 'Password',
    'manager.pages.contract.contractdetail.logkout': 'Lockout',
    'manager.pages.contract.contractdetail.plan': 'Plan',
    'manager.pages.contract.contractdetail.grade': 'Grade',
    'manager.pages.contract.contractdetail.company.id': 'Company ID',
    'manager.pages.contract.contractdetail.disk.size': 'Disk size(MB)',
    'manager.pages.contract.contractdetail.request.date': 'Request date',
    'manager.pages.contract.contractdetail.approve.date': 'Approve date',
    'manager.pages.contract.contractdetail.start.date': 'Start Date',
    'manager.pages.contract.contractdetail.mumber.of.members': 'Number of members',
    'manager.pages.contract.contractdetail.mumber.of.partners': 'Number of partners',
    'manager.pages.contract.contractdetail.ipad': 'iPad数（割当/全体)',
    'manager.pages.contract.contractdetail.admin1': 'Administrator 1',
    'manager.pages.contract.contractdetail.admin2': 'Administrator 2',
    'manager.pages.contract.contractdetail.company.name': 'Company name',
    'manager.pages.contract.contractdetail.billing.department': 'Billing department',
    'manager.pages.contract.contractdetail.billing.name': 'Billing name',
    'manager.pages.contract.contractdetail.billing.phone': 'Billing phone number',
    'manager.pages.contract.contractdetail.billing.address': 'Billing address',
    'manager.pages.contract.contractdetail.button.approve': 'Approve',
    'manager.pages.contract.contractdetail.button.save': 'Save',
    'manager.pages.contract.contractdetail.button.unlock': 'Unlock',
    'manager.pages.contract.contractdetail.button.changepassword': 'Change password',
    'manager.pages.contract.contractdetail.button.suspend': 'Suspend',
    'manager.pages.contract.contractdetail.button.delete': 'Delete',
    'manager.pages.contract.contractdetail.persons.team': 'persons / team',
    'manager.pages.contract.contractdetail.persons.map': 'persons / map',
    'manager.pages.contract.contractdetail.button.awake': 'Awake',
    'manager.pages.contract.contractdetail.button.lock': 'Lock',
    'manager.pages.contract.contractdetail.button.cancel': 'Cancel',
    'manager.pages.contract.contractdetail.button.approve.modal.title': 'Approve this account ?',
    'manager.pages.contract.contractdetail.button.unlock.modal.title': 'Unlock this account ?',
    'manager.pages.contract.contractdetail.button.save.modal.title': 'Save this account ?',
    'manager.pages.contract.contractdetail.button.change.password.modal.title': 'ChangePassword this account ?',
    'manager.pages.contract.contractdetail.button.password.modal.title': 'Enter your password...',
    'manager.pages.contract.contractdetail.button.change.password.modal.button': 'ChangePassword',
    'manager.pages.contract.contractdetail.button.change.password.submit.modal.button': 'Submit',
    'manager.pages.contract.contractdetail.mb': 'MB',
    'manager.pages.contract.contractdetail.button.change.password.submit.modal.alert': 'wrong password.',
    'manager.pages.contract.contractdetail.button.suspend.modal.title': 'Suspend this account ?',
    'manager.pages.contract.contractdetail.button.awake.modal.title': 'Awake this account ?',

    'manager.pages.contract.contractdetail.button.change.delete.modal.button': 'Delete',
    'manager.pages.contract.contractdetail.button.delete.modal.title': 'Delete this account ?',
    'manager.pages.contract.contractdetail.button.download.tsv.type.k-board': 'K-Board',
    'manager.pages.contract.contractdetail.button.download.tsv.type.manager': 'Manager',
    'manager.pages.contract.contractdetail.button.download.tsv.download.contents': 'Download contents',
    'manager.pages.contract.contractdetail.button.download.tsv.log.type': 'Log Type',
    'manager.pages.contract.contractdetail.button.download.tsv.periodstart': 'Period start',
    'manager.pages.contract.contractdetail.button.download.tsv.periodend': 'Period end',
    'manager.pages.contract.contractdetail.button.download.tsv.cancel': 'Cancel',
    'manager.pages.contract.contractdetail.button.download.tsv.download': 'Download',
    'manager.pages.contract.contractdetail.button.download.tsv.error.button': 'OK',
    'manager.pages.contract.contractdetail.button.download.tsv.error': 'Operation Log is empty or not found.',

    'manager.pages.admin.button.addaccount': 'Add Account',
    'manager.pages.admin.body.no': 'No',
    'manager.pages.admin.body.userid': 'UserId',
    'manager.pages.admin.body.name': 'Name',
    'manager.pages.admin.body.email': 'Email',
    'manager.pages.admin.body.remarks': 'Remarks',
    'manager.pages.admin.body.registered': 'Registered',
    'manager.pages.admin.body.lastloggedin': 'Last logged in',
    'manager.pages.admin.body.deleted': 'Deleted',
    'manager.pages.stats.download.tsv.error': 'Stats is empty or not found.',
    'manager.pages.stats.download.tsv.error.button': 'OK',
    'manager.pages.stats.download.tsv.download.label': 'Download',
    'manager.pages.stats.download.tsv.records': 'Records',
    'manager.pages.stats.download.tsv.button.cancel': 'Cancel',
    'manager.pages.stats.download.tsv.button.download': 'Download',
    'manager.pages.stats.download.tsv.period.start': 'Period start',
    'manager.pages.stats.download.tsv.period.end': 'Period end',

    'manager.pages.admin.back': 'Back',
    'manager.pages.admin.login.id': 'Login ID',
    'manager.pages.admin.first.name': 'FirstName',
    'manager.pages.admin.last.name': 'LastName',
    'manager.pages.admin.email': 'Email',
    'manager.pages.admin.timezone': 'Timezone',
    'manager.pages.admin.registered': 'Registered',
    'manager.pages.admin.last.logged.in': 'Last logged in',
    'manager.pages.admin.deleted': 'Deleted',
    'manager.pages.admin.roles': 'Roles',
    'manager.pages.admin.roles.stats': 'Stats',
    'manager.pages.admin.roles.user': 'User',
    'manager.pages.admin.roles.contract': 'Contract',
    'manager.pages.admin.roles.admin': 'Admin',
    'manager.pages.admin.remarks': 'Remarks',
    'manager.pages.admin.change.password': 'Change password',
    'manager.pages.admin.save.this.changes': 'Save this changes',
    'manager.pages.admin.view.more.logs': 'View more logs',
    'manager.pages.admin.delete.account': 'Delete account',
    'manager.pages.admin.operation.log': 'Operation Log',
    'manager.pages.admin.operation.log.date': 'Date',
    'manager.pages.admin.operation.log.action': 'Action',
    'manager.pages.admin.delete.account.modal.basic.title': 'Delete this account ?',
    'manager.pages.admin.delete.account.modal.basic.cancel': 'Cancel',
    'manager.pages.admin.delete.account.modal.basic.delete': 'Delete',
    'manager.pages.admin.delete.account.modal.primary.title': 'Enter your password...',
    'manager.pages.admin.password': 'Password',
    'manager.pages.admin.password.confirm': 'Password confirm',
    'manager.pages.admin.delete.account.modal.primary.cancel': 'Cancel',
    'manager.pages.admin.delete.account.modal.primary.submit': 'Submit',
    'manager.pages.admin.save.account.message': 'Save this ?',
    'manager.pages.admin.save.account.cancel': 'Cancel',
    'manager.pages.admin.save.account.save': 'Save',
    'manager.pages.admin.error.input': 'ERROR:',
    'manager.pages.admin.period': 'Period',
    'manager.pages.admin.search': 'Search',
    'manager.pages.admin.download.tsv': 'Download TSV',
    'manager.pages.admin.body.date': 'Date',
    'manager.pages.admin.body.operator': 'Operator',
    'manager.pages.admin.body.category': 'Category',
    'manager.pages.admin.body.action': 'Action',
    'manager.pages.admin.userId': 'User ID',

    'manager.pages.admin.admindetail.delete.account.modal.primary.ok': 'OK',
    
    'manager.pages.admin.adminlog.back': 'Back',
    'manager.pages.admin.adminlog.period': 'Period',
    'manager.pages.admin.adminlog.search': 'Search',
    'manager.pages.admin.adminlog.download.tsv': 'Download TSV',
    'manager.pages.admin.adminlog.body.date': 'Date',
    'manager.pages.admin.adminlog.body.operator': 'Operator',
    'manager.pages.admin.adminlog.body.category': 'Category',
    'manager.pages.admin.adminlog.body.action': 'Action',
    'manager.pages.admin.adminlog.body.search.limit': 'Found more than {{ searchLimit }} logs.Please Limit your search criteria.',
    'manager.pages.admin.sql.error': 'SQL error\n<MS0006>',
    'manager.pages.admin.admin.save.account': 'Save account',
    'manager.pages.contract.contractdetail.image.feature': 'Poster Feature',
    'manager.pages.contract.contractdetail.image.feature.valid': 'valid',
    'manager.pages.contract.contractdetail.image.item.feature': 'Image Item Feature ＆ Preset Feature',
    'manager.pages.contract.contractdetail.image.item.feature.valid': 'valid',
    'manager.pages.contract.contractdetail.update.type.save.success': '設定が変更されました。',
    
})
