export default {
    "admin.notfound.error": `Admin is not found.`,
    "mail.send.error": `Message sending failed.\n<MS0002>`,
    "auth.logon.error": `Login error`,
    "auth.password.error": `Incorrect user ID or password.`,
    "team.notfound.error": `Team is not found.`,
    "stats.data.empty": `The specified statistical information does not exist.`,
    "admin.add.already.error": `Registration is already complete.`,
    "system.fatalerror": `A system error has occurred.\n<MS0001>`,
    "delete.password.error": `wrong password.`,
    "auth.logon.success": `Login successful.`,
    "session.timeout": `Session Timeout\n<MS0003>`,
    "entity.lock.error": `An optimistic locking conflict has occurred. \n<MS0005>`,
    "sql.error": `SQL error\n<MS0006>`,
    "user.notfound.error": `User is not found.`,
    "entity.conflict.error": `Entity unique constraint violation\n<MS0004>`,
    "contract.approve.already.error": `Approve is already complete.`,
    "delete.admin.user.error": `Admin user is not delete.`,
};
