import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";

import PrealoadDisplay from 'wlp-client-manager-lib/components/PreloadDisplay/PreloadDisplay';

const delayLoad = 700;
const Manager = React.lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('wlp-client-manager/Main')), delayLoad);
    });
});

const Main = () => {
    return (
        <React.Suspense fallback={<PrealoadDisplay delayLoad={delayLoad} />}>
            <Router>
                <Switch>
                    <Route exact path="/">
                        <Manager />
                    </Route>
                    <Redirect from="/*" to="/" />
                </Switch>
            </Router>
        </React.Suspense>
    )
}

export default Main;