class LocalStorageUtils {

    STRAGE_LOCALE_CODE = 'localeCode';

    static STRAGE_ACCESS_TOKEN = 'accessToken'
    
    setLocalStrage(strageName, dataObject) {
        localStorage.setItem(strageName, JSON.stringify(dataObject));
    }



    getLocalStrage(strageName) {
        const strageData = localStorage.getItem(strageName);
        try {
            return JSON.parse(strageData);
        } catch (e) {
            return strageData;
        }
    }

    getLocaleCode(){
        return this.getLocalStrage(this.STRAGE_LOCALE_CODE);
    }

    getLocalStrageItem(strageName) {
        var strageData = localStorage.getItem(strageName);
        if (strageData) {
            return strageData;
        } else {
            return null;
        }
    }

    deleteLocalStrage(strageName) {
        localStorage.removeItem(strageName);
    }

    setAccessToken(token){
        this.setLocalStrage('accessToken', token);
    }

    getAccessToken(){
        return this.getLocalStrage('accessToken');
    }
}
export default LocalStorageUtils;