export default {
    "system.fatalerror": `\u30B7\u30B9\u30C6\u30E0\u304C\u6B63\u3057\u304F\u52D5\u3044\u3066\u3044\u307E\u305B\u3093\u3002\u30D6\u30E9\u30A6\u30B6\u3092\u518D\u8D77\u52D5\u3057\u30ED\u30B0\u30A4\u30F3\u3057\u76F4\u3057\u3066\u307F\u3066\u4E0B\u3055\u3044\u3002\u30A8\u30E9\u30FC\u30B3\u30FC\u30C9\uFF1A<0001>`,
    "mail.send.error": `\u30E1\u30FC\u30EB\u9001\u4FE1\u306B\u5931\u6557\u3057\u307E\u3057\u305F\u3002\u30D6\u30E9\u30A6\u30B6\u3092\u518D\u8D77\u52D5\u3057\u3001\u30ED\u30B0\u30A4\u30F3\u3057\u76F4\u3057\u3066\u307F\u3066\u4E0B\u3055\u3044\u3002\u30A8\u30E9\u30FC\u30B3\u30FC\u30C9\uFF1A<0002>`,
    "session.timeout": `\u5B89\u5168\u306E\u70BA\u3001\u81EA\u52D5\u30ED\u30B0\u30A2\u30A6\u30C8\u3057\u307E\u3057\u305F\u3002\u304A\u624B\u6570\u3067\u3059\u304C\u518D\u5EA6\u30ED\u30B0\u30A4\u30F3\u3057\u3066\u304F\u3060\u3055\u3044\u3002\u30A8\u30E9\u30FC\u30B3\u30FC\u30C9\uFF1A<0003>`,
    "entity.conflict.error": `\u7533\u3057\u8A33\u3042\u308A\u307E\u305B\u3093\u3002\u64CD\u4F5C\u306B\u5931\u6557\u3057\u307E\u3057\u305F\u3002\u4E00\u5EA6\u4ED6\u30DE\u30C3\u30D7\u306B\u79FB\u52D5\u3057\u3066\u307F\u308B\u304B\u3001\u30D6\u30E9\u30A6\u30B6\u3092\u518D\u8D77\u52D5\u3057\u30ED\u30B0\u30A4\u30F3\u3057\u76F4\u3057\u3066\u307F\u3066\u4E0B\u3055\u3044\u3002\u30A8\u30E9\u30FC\u30B3\u30FC\u30C9\uFF1A<0004>`,
    "entity.lock.error": `\u7533\u3057\u8A33\u3042\u308A\u307E\u305B\u3093\u3002\u64CD\u4F5C\u306B\u5931\u6557\u3057\u307E\u3057\u305F\u3002\u4E00\u5EA6\u4ED6\u30DE\u30C3\u30D7\u306B\u79FB\u52D5\u3057\u3066\u307F\u308B\u304B\u3001\u30D6\u30E9\u30A6\u30B6\u3092\u518D\u8D77\u52D5\u3057\u30ED\u30B0\u30A4\u30F3\u3057\u76F4\u3057\u3066\u307F\u3066\u4E0B\u3055\u3044\u3002\u30A8\u30E9\u30FC\u30B3\u30FC\u30C9\uFF1A<0005>`,
    "sql.error": `\u7533\u3057\u8A33\u3042\u308A\u307E\u305B\u3093\u3002\u64CD\u4F5C\u306B\u5931\u6557\u3057\u307E\u3057\u305F\u3002\u30D6\u30E9\u30A6\u30B6\u3092\u518D\u8D77\u52D5\u3057\u30ED\u30B0\u30A4\u30F3\u3057\u76F4\u3057\u3066\u307F\u3066\u4E0B\u3055\u3044\u3002\u30A8\u30E9\u30FC\u30B3\u30FC\u30C9\uFF1A<0006>`,
    "auth.logon.success": `\u30ED\u30B0\u30A4\u30F3\u306B\u6210\u529F\u3057\u307E\u3057\u305F\u3002`,
    "auth.logon.error": `\u30e1\u30fc\u30eb\u30a2\u30c9\u30ec\u30b9\u3082\u3057\u304f\u306f\u30d1\u30b9\u30ef\u30fc\u30c9\u304c\n\u6b63\u3057\u304f\u306a\u3044\u3088\u3046\u3067\u3059\u3002`,
    "auth.password.error": `\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u307E\u305F\u306F\u30D1\u30B9\u30EF\u30FC\u30C9\u304C\u9055\u3044\u307E\u3059\u3002`,
    "delete.password.error": `\u30d1\u30b9\u30ef\u30fc\u30c9\u304c\u9055\u3044\u307e\u3059\u3002`,
    "admin.add.already.error": `\u3059\u3067\u306b\u767b\u9332\u6e08\u3067\u3059\u3002`,
    "stats.data.empty": `\u6307\u5b9a\u306e\u7d71\u8a08\u60c5\u5831\u306f\u3042\u308a\u307e\u305b\u3093\u3002`,
    "user.notfound.error": `\u30e6\u30fc\u30b6\u304c\u898b\u3064\u304b\u3089\u306a\u3044\u3002`,
    "team.notfound.error": `\u30c1\u30fc\u30e0\u304c\u898b\u3064\u304b\u3089\u306a\u3044\u3002`,
    "admin.notfound.error": `\u7ba1\u7406\u30e6\u30fc\u30b6\u304c\u898b\u3064\u304b\u3089\u306a\u3044\u3002`,
    "contract.approve.already.error": `\u3059\u3067\u306b\u627f\u8a8d\u6e08\u307f\u3067\u3059\u3002`,
    "delete.admin.user.error": `\u7ba1\u7406\u8005\u30e6\u30fc\u30b6\u306f\u524a\u9664\u3067\u304d\u307e\u305b\u3093\u3002`,
};
